// import * as Sentry from '@sentry/sveltekit'
import type { HandleClientError } from '@sveltejs/kit'
import { reportError } from './lib/utils/track'
// import config from './config'

const isError = (error: unknown): error is Error => {
	return error instanceof Error || (error && typeof error === 'object' && 'message' in error)
}

declare type CodedError = {
	code: string
}

export const isCodedError = (error: unknown): error is CodedError => {
	return isError(error) && 'code' in error
}

// Basic error handler since Sentry is commented out
export const handleError: HandleClientError = ({ error, event }) => {
	if (event?.url?.pathname == '_app/version.json') {
		console.warn('Failed to fetch version.json')
		return
	}

	if (isError(error)) {
		if (error.message.includes('Not found') || error.message.includes('not Found')) {
			return {
				status: 404,
				message: 'Not found',
				code: 'NotFound',
			}
		}

		console.error(error)

		// @ts-ignore
		if (import.meta.env.PROD) reportError(error, event)

		return {
			message: error.message ?? 'Internal Error',
			code: isCodedError(error) ? error.code : 'UNKNOWN',
			stack: error.stack,
		}
	}

	return {
		message: 'Internal Error',
	}
}

// Add the init function that SvelteKit expects
export const init = () => {
	// You can initialize any client-side services here
	return {
		// Return any data you want available on the client
	}
}
